const languages = [
  { name: "English", code: "eng", surveyLocalization: "en_US" },
  { name: "Japanese", code: "jap", surveyLocalization: "ja_JP" },
];

const surveyData = [
  { label: "Augment Survey", value: 233 },
  { label: "Automotive", value: 211 },
  { label: "Baby Care", value: 212 },
  { label: "Beverage", value: 213 },
  { label: "Consumer Electronics", value: 214 },
  { label: "Dining", value: 215 },
  { label: "Education", value: 216 },
  { label: "Exciting New", value: 232 },
  { label: "Fashion and Accessories", value: 217 },
  { label: "Financial Services", value: 218 },
  { label: "Food", value: 219 },
  { label: "Healthcare", value: 220 },
  { label: "Home and Garden", value: 221 },
  { label: "Homecare", value: 222 },
  { label: "Lifestyles and Hobbies", value: 223 },
  { label: "Media and Entertainment", value: 224 },
  { label: "Office Supplies", value: 225 },
  { label: "Personal Care and Beauty", value: 226 },
  { label: "Pet Care", value: 227 },
  { label: "Shopping and Retail", value: 228 },
  { label: "Technology and Computing", value: 229 },
  { label: "Toys and Games", value: 230 },
  { label: "Travel", value: 231 },
];

const genderData = [
  { label: "Male", value: "111" },
  { label: "Female", value: "112" },
  { label: "Prefer not to say", value: "113" },
];
export { languages, surveyData, genderData };
