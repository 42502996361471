import { Box, Button, Flex, Heading, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RenderQuestion } from "./RenderQuestion";
import { useRecoilState } from "recoil";
import { ProductState } from "../state/atom/ProductState";
import { getSurvey, updateSurveyQuestions } from "../utils/Helper";
import Loader from "./Loader";

const UpdateExisting = ({ clientId, productId }) => {
  const [searchParams] = useSearchParams();
  const surveyName = searchParams.get("surveyName");
  const navigate = useNavigate();
  const [product, setProduct] = useRecoilState(ProductState);
  const [screeningQuestions, setScreeningQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [closingMessages, setclosingMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    getSurvey(clientId, productId, surveyName).then((res) => {
      const lang = res?.data?.language;
      
      const screeningQuestions =
        Object.values(res?.data?.screenerQuestions) || [];
      const regularQuestions =
        Object.values(res?.data?.questions) || [];
      const goodbyeMsg = res?.data?.closingMessages || [];
      // Sort screening questions by sequence
      screeningQuestions.sort((a, b) => a.sequence - b.sequence);

      // Sort regular questions by sequence
      regularQuestions.sort((a, b) => a.sequence - b.sequence);

      goodbyeMsg?.sort((a, b) => a.sequence - b.sequence);
      setLanguage(lang);
      setScreeningQuestions(screeningQuestions);
      setclosingMessages(goodbyeMsg);
      setQuestions(regularQuestions);
      setProduct(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  const updateScreeningQuestion = (index, newValue) => {
    const updatedScreeningQuestions = [...screeningQuestions];
    updatedScreeningQuestions[index] = {
      ...updatedScreeningQuestions[index],
      question: newValue,
    };
    setScreeningQuestions(updatedScreeningQuestions);
  };

  const updateScreeningOption = (index, optionIndex, option) => {
    const updatedScreeningQuestions = [...screeningQuestions];
    const updatedOptions = [...updatedScreeningQuestions[index].options];
    updatedOptions[optionIndex] = option;
    updatedScreeningQuestions[index] = {
      ...updatedScreeningQuestions[index],
      options: updatedOptions,
    };
    setScreeningQuestions(updatedScreeningQuestions);
  };

  const updateMessages = (index, newValue) => {
    const updatedScreeningQuestions = [...closingMessages];
    updatedScreeningQuestions[index] = {
      ...updatedScreeningQuestions[index],
      value: newValue,
    };

    setclosingMessages(updatedScreeningQuestions);
  };

  const HandleSubmit = (e) => {
    setIsBtnLoading(true);

    // Separate screener and regular questions
    const updatedScreenerQuestions = screeningQuestions;
    const updatedRegularQuestions = questions;
    const updatedMessages = closingMessages;
    updateSurveyQuestions(
      productId,
      updatedRegularQuestions,
      updatedScreenerQuestions,
      updatedMessages,
      language,
      surveyName
    ).then((res) => {
      toast({
        title: `Questions saved successfully.`,
        position: "top-right",
        isClosable: true,
        variant: "left-accent",
        status: "success",
      });
      setIsBtnLoading(false);
    });
  };

  const updateQuestion = (index, newValue) => {
    const updatedQuestions = [...questions];
    const updatedQuestion = {
      ...updatedQuestions[index],
      question: newValue,
    };

    if (updatedQuestion.isScreener) {
      const updatedScreeningQuestions = [...screeningQuestions];
      updatedScreeningQuestions[index] = updatedQuestion;
      setScreeningQuestions(updatedScreeningQuestions);
    } else {
      updatedQuestions[index] = updatedQuestion;
      setQuestions(updatedQuestions);
    }
  };

  const updateOption = (index, optionIndex, option) => {
    const updatedQuestions = [...questions];
    const updatedOptions = [...updatedQuestions[index].options];
    updatedOptions[optionIndex] = option;
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      options: updatedOptions,
    };

    if (updatedQuestions[index].isScreener) {
      const updatedScreeningQuestions = [...screeningQuestions];
      updatedScreeningQuestions[index] = updatedQuestions[index];
      setScreeningQuestions(updatedScreeningQuestions);
    } else {
      setQuestions(updatedQuestions);
    }
  };

  const updateNestedQuestion = (index, optionIndex, option) => {
    const updatedQuestions = [...questions];
    const updatedOptions = [...updatedQuestions[index].nestedQuestion];
    updatedOptions[optionIndex] = option;
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      nestedQuestion: updatedOptions,
    };

    if (updatedQuestions[index].isScreener) {
      const updatedScreeningQuestions = [...screeningQuestions];
      updatedScreeningQuestions[index] = updatedQuestions[index];
      setScreeningQuestions(updatedScreeningQuestions);
    } else {
      setQuestions(updatedQuestions);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Box
      m={2}
      sx={{
        display: "flex",
        flexDir: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        m={2}
        sx={{
          width: 600,
          maxW: 1200,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Heading as={"h6"} size="lg" mt={2} textAlign={"center"}>
          Product name: {product?.name}
        </Heading>
        <form style={{ width: "100%" }}>
          {screeningQuestions.length > 0 && (
            <div>
              <Heading
                as={"h2"}
                size="lg"
                color={"gray.600"}
                mt={2}
                mb={5}
                textAlign={"center"}
              >
                Screening Questions
              </Heading>
              {screeningQuestions.map((question, index) => {
                return (
                  <RenderQuestion
                    question={question}
                    index={index + 1}
                    key={question.questionId}
                    onChange={(value) => updateScreeningQuestion(index, value)}
                    onChangeOption={(optionIndex, newValue) =>
                      updateScreeningOption(index, optionIndex, newValue)
                    }
                    onChangeNestedQuestion={(optionIndex, newValue) =>
                      updateNestedQuestion(index, optionIndex, newValue)
                    }
                  />
                );
              })}
            </div>
          )}
          <div>
            <Heading
              as={"h2"}
              size="lg"
              mt={3}
              color={"gray.600"}
              textAlign={"center"}
            >
              Questions
            </Heading>
            {questions.map((question, index) => {
              return (
                <RenderQuestion
                  question={question}
                  index={index + 1}
                  key={question.questionId}
                  onChange={(value) => updateQuestion(index, value)}
                  onChangeOption={(optionIndex, newValue) =>
                    updateOption(index, optionIndex, newValue)
                  }
                  onChangeNestedQuestion={(optionIndex, newValue) =>
                    updateNestedQuestion(index, optionIndex, newValue)
                  }
                />
              );
            })}
          </div>
          <div>
            <Heading
              as={"h2"}
              size="lg"
              mt={3}
              color={"gray.600"}
              textAlign={"center"}
            >
              Closing Messages
            </Heading>
            {closingMessages.map((question, index) => {
              return (
                <RenderQuestion
                  question={question}
                  index={index + 1}
                  key={question.questionId}
                  onChange={(value) => updateMessages(index, value)}
                />
              );
            })}
          </div>
          <Flex mt={6} mb={5} justifyContent="center">
            <Button
              colorScheme="teal"
              size="lg"
              mr={5}
              onClick={() =>
                navigate(
                  `/list-existing?clientId=${clientId}&surveyName=${surveyName}`
                )
              }
            >
              Back
            </Button>
            <Button
              colorScheme="teal"
              size="lg"
              onClick={HandleSubmit}
              isLoading={isBtnLoading}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default UpdateExisting;
