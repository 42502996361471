import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomCard from "./Card";
import { deleteSurvey, getUserSurveysDetails } from "../utils/Helper";
import Loader from "./Loader";

const ListExisting = ({ clientId }) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const surveyName = searchParams.get("surveyName");
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    getUserSurveysDetails(clientId, surveyName).then((res) => {
      setData(res);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getUserSurveysDetails(clientId, surveyName).then((res) => {
      setData(res);
    });
  }, [refetch]);

  const HandleDelete = (clientId, survayId) => {
    setDeleteLoading(survayId);
    deleteSurvey(clientId, survayId, surveyName).then(() => {
      getUserSurveysDetails(clientId, surveyName).then((res) => {
        setData(res);
        setDeleteLoading("");
      });
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <Box
      m={2}
      sx={{
        display: "flex",
        flexDir: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          maxW: "auto",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Heading
          as={"h6"}
          size="lg"
          mt={2}
          textAlign={"center"}
          color={"gray.600"}
          maxW={500}
        >
          Survey List
        </Heading>
        <Flex
          flexWrap={"wrap"}
          width={"100%"}
          maxW={"100%"}
          justifyContent={"center"}
        >
          {data?.length ? (
            data?.map((survay, index) => {
              return (
                <Flex mt={3} mb={2} mr={5} key={survay + index}>
                  <CustomCard
                    survay={survay.data}
                    clientId={clientId}
                    HandleDelete={HandleDelete}
                    deleteLoading={deleteLoading}
                    setRefetch={setRefetch}
                    surveyName={surveyName}
                    setData={setData}
                  />
                </Flex>
              );
            })
          ) : (
            <Box mt={5} mb={5}>
              <Heading color={"gray.300"}>None So Far...</Heading>
            </Box>
          )}
        </Flex>

        <Flex mt={3}>
          <Button
            colorScheme="teal"
            size="lg"
            mr={5}
            onClick={() =>
              navigate(
                `/create-new?clientId=${clientId}&surveyName=${surveyName}`
              )
            }
          >
            Create New Survey
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ListExisting;
