import React, { useEffect, useState } from "react";
import { GetUserDetails, getUserSurveysDetails } from "./utils/Helper";
import { Route, Routes, useSearchParams } from "react-router-dom";
import Home from "./pages/Home";
import ListExisting from "./components/ListExisting";
import CreateNew from "./components/CreateNew";
import CreateNewForm from "./components/CreateNewForm";
import UpdateExisting from "./components/UpdateExisting";
import PageNotFound from "./pages/PageNotFound";
import Loader from "./components/Loader";
import { surveys } from "./utils/constant";

const Routing = () => {
  const [isValidUser, setIsValidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get("clientId");
  const productId = searchParams.get("product");
  useEffect(() => {
    GetUserDetails(clientId)
      .then((result) => {
        setIsValidUser(Object.values(surveys) && result?.success);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getUserSurveysDetails(clientId)
      .then(() => {})
      .catch((err) => {});
  }, [clientId]);

  return isLoading ? (
    <Loader />
  ) : (
    <Routes>
      {isValidUser && !isLoading ? (
        <>
          <Route path="/" element={<Home clientId={clientId} />} />
          <Route
            path="/list-existing"
            element={<ListExisting clientId={clientId} />}
          />
          <Route
            path="/create-new"
            element={<CreateNew clientId={clientId} />}
          />
          <Route
            path="/create-new-form"
            element={<CreateNewForm clientId={clientId} />}
          />
          <Route
            path="/update-existing"
            element={
              <UpdateExisting clientId={clientId} productId={productId} />
            }
          />
        </>
      ) : (
        <Route path="*" element={<PageNotFound />} />
      )}
    </Routes>
  );
};

export default Routing;
