const dev = "http://localhost:4000";
const prod = "https://us-central1-genbotnoetic.cloudfunctions.net/chatbot";

export const URL =
  window.location.hostname.split(":")[0] === "localhost" ||
  window.location.hostname.includes("192")
    ? dev
    : prod;

export const surveys = {
  livingConceptSurveys: "livingConceptSurveys",
  feedbackSurvey: "feedbackSurvey",
};
export const headings = {
  livingConceptSurveys: "Create New Living Concept Survey",
  feedbackSurvey: "Create New Feedback Survey",
};

export const surveyNameMapping = {
  livingConceptSurveys: "concept",
  feedbackSurvey: "feedback"
}