import { Select } from "@chakra-ui/react";
import React from "react";

const Dropdown = ({ selectedValue, setSelectedValue }) => {
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Select
      placeholder="Select Surveys"
      onChange={handleSelectChange}
      value={selectedValue}
      id="dropdown"
      name="dropdown"
      required
      sx={{ width: "100%", minW: 200 }}
    >
      <option value="livingConceptSurveys">livingConceptSurveys</option>
      <option value="feedbackSurvey">feedbackSurvey</option>
    </Select>
  );
};

export default Dropdown;
