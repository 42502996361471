import * as React from "react";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./utils/theme";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </RecoilRoot>
    </ChakraProvider>
  );
}
export default App;
