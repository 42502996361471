import {  Button, Flex, Heading, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import { surveys } from "../../utils/constant";

const Home = ({ clientId }) => {
  const [searchParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState("");
  const navigate = useNavigate();
  const surveyName = searchParams.get("surveyName");

  let isDisabled = !selectedValue?.length;
  let survey = selectedValue?.length
    ? selectedValue
    : surveyName !== "null"
    ? surveyName
    : undefined;
  useEffect(() => {
    if (surveyName && surveys[surveyName]) {
      setSelectedValue(surveyName);
    }
  }, [surveyName]);
  return (
    <Box
      m={2}
      sx={{
        display: "flex",
        flexDir: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      {!isDisabled && (
        <Heading
          as={"h6"}
          size="lg"
          mt={2}
          textAlign={"center"}
          color={"gray.600"}
          maxW={500}
          disabled={isDisabled}
        >
          Do you want to create a survey or update an existing survey?
        </Heading>
      )}
      <Flex mt={3} display={"flex"} flexWrap={"wrap"} justifyContent={"center"}>
        <Heading
          as={"span"}
          size="sm"
          my={2}
          textAlign={"center"}
          color={"gray.600"}
          maxW={500}
        >
          Please select a survey to proceed further:
        </Heading>
        <Dropdown
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
        />
      </Flex>

      {!isDisabled && (
        <Flex
          mt={3}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          <Button
            colorScheme="teal"
            size="lg"
            mr={5}
            mb={5}
            onClick={() =>
              isDisabled
                ? () => {}
                : navigate(
                    `/create-new?clientId=${clientId}&surveyName=${survey}`
                  )
            }
            disabled={isDisabled}
          >
            Create New
          </Button>
          <Button
            colorScheme="teal"
            size="lg"
            onClick={() =>
              isDisabled
                ? () => {}
                : navigate(
                    `/list-existing?clientId=${clientId}&surveyName=${survey}`
                  )
            }
            disabled={isDisabled}
          >
            Update Existing
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Home;
