import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DefaultQuestionEng } from "../utils/Helper";
import { RenderQuestion } from "./RenderQuestion";

const CreateNewForm = ({ clientId }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const survey = searchParams.get("survey");

  let formatedObj = {
    livingConceptSurveys: DefaultQuestionEng.livingConceptSurveys,
    feedbackSurvey: DefaultQuestionEng.feedbackSurvey,
  };

  return (
    <Box
      m={2}
      sx={{
        display: "flex",
        flexDir: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 600,
          maxW: 1200,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Heading
          as={"h6"}
          size="lg"
          mt={2}
          textAlign={"center"}
          color={"gray.600"}
          mb={4}
        >
          Product ABCD
        </Heading>

        {formatedObj[survey]?.map((question, index) => {
          return <RenderQuestion question={question} index={index + 1} />;
        })}

        <Flex mt={3}>
          <Button
            colorScheme="teal"
            size="lg"
            mr={5}
            onClick={() => navigate(`/?clientId=${clientId}`)}
          >
            Back
          </Button>

          <Button
            colorScheme="teal"
            size="lg"
            onClick={() => navigate(`/?clientId=${clientId}`)}
          >
            Create
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default CreateNewForm;
