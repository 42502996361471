import React from "react";
import { Select } from "@chakra-ui/react";

const CustomSelect = (props) => {
  const { placeholder, name, value, onChange } = props;
  const ageArray = ["45 - 74"];
  return (
    <Select
      defaultValue={value || ""}
      placeholder={placeholder}
      variant="outline"
      name={name}
      onChange={onChange}
      numeric="numeric"
    >
      {ageArray.map((item, ind) => {
        return (
          <option value={item} key={ind}>
            {`${item} (7 Men + 7 Women)`}
          </option>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
