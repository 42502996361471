import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { collection, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadString,
  deleteObject,
} from "firebase/storage";
import i18n from "../locales";
import axios from "axios";
import { surveyNameMapping, URL } from "./constant";

export const GetUserDetails = async (userId) => {
  if (!userId) return;
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      return { success: true, data: userDocSnapshot.data() };
    } else {
      return { success: false, error: { message: "User Not Found" } };
    }
  } catch (error) {
    return { success: false, error };
  }
};

// get survay ids and their names and questions
export const getUserSurveysDetails = async (userId, surveyName) => {
  
  const userRef = doc(db, "users", userId);

  try {
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const surveyIds = userData.surveyIds || [];
      const surveyDetailsPromises = surveyIds.map(async (surveyId) => {
        const surveyRef = doc(db, "surveys", surveyId);
        const surveyDoc = await getDoc(surveyRef);

        if (surveyDoc.exists() && (surveyDoc.data()?.campaign_type)?.toLowerCase() === surveyNameMapping[surveyName]) {

          return {
            id: surveyId,
            data: { ...surveyDoc.data(), id: surveyId },
          };
        } else {
          return null;
        }
      });

      const surveyDetails = await Promise.all(surveyDetailsPromises);

      return surveyDetails.filter((detail) => detail !== null);
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching user survey data:", error);
    throw error;
  }
};

export const deleteSurvey = async (userId, surveyId, surveyName) => {
  try {
    const surveysCollectionRef = collection(db, "surveys");
    const surveyRef = doc(surveysCollectionRef, surveyId);

    // Check if the survey document exists before attempting deletion
    const surveyDoc = await getDoc(surveyRef);

    if (!surveyDoc.exists()) {
      return { success: false, message: "Survey not found" };
    }

    // Delete the survey document by its reference
    await deleteDoc(surveyRef);

    // Update the user's document to remove the deleted survey ID from the "surveyIds" field
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      surveyIds: arrayRemove(surveyId),
    });

    return { success: true };
  } catch (error) {
    console.error("Error deleting survey:", error);
    throw error;
  }
};

export const getSurvey = async (userId, surveyId, surveyName) => {
  try {
    // Reference to the user's document
    const userRef = doc(db, "users", userId);

    // Check if the user document exists
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return { success: false, message: "User not found" };
    }

    // Ensure that the surveyId exists in the user's "surveyIds" field
    const userData = userDoc.data();
    const surveyIds = userData.surveyIds || [];

    if (!surveyIds.includes(surveyId)) {
      return { success: false, message: "Survey not found for this user" };
    }

    // Reference to the specific survey document
    const surveysCollectionRef = collection(db, "surveys");
    const surveyRef = doc(surveysCollectionRef, surveyId);

    // Get the survey document
    const surveyDoc = await getDoc(surveyRef);

    if (!surveyDoc.exists()) {
      return { success: false, message: "Survey not found" };
    }

    const surveyData = surveyDoc.data();
    // Sort the questions by sequence
    surveyData.questions = Object.values(surveyData?.questions)
      .sort((a, b) => a.sequence - b.sequence)
      .reduce((questionMap, question) => {
        questionMap[question.questionId] = question;
        return questionMap;
      }, {});

    return { success: true, data: surveyData };
  } catch (error) {
    console.error("Error fetching survey:", error);
    throw error;
  }
};

export const createSurveys = async (
  userId,
  surveys,
  language,
  surveyName,
  survey_Localization
) => {
  let surveyDataResponse;
  try {
    const surveysCollectionRef = collection(db, "surveys");
    // Update with your Firestore collection name
    const userRef = doc(db, "users", userId);

    const surveyId = generateUniqueSurveyId();
    const createSurveyPromises = surveys.map(async (survey, i) => {
      const surveyRef = doc(surveysCollectionRef, surveyId);

      const existingSurveyDoc = await getDoc(surveyRef);

      if (existingSurveyDoc.exists()) {
        // Handle the situation as needed, e.g., skip or update the existing survey.
        return null;
      } else {
        const imageFile = surveys[i]?.image; // Check if imageFiles is defined and has elements

        // Initialize imageUrl as null
        let imageUrl = null;
        if (imageFile) {
          // Upload the image to Firebase Storage          
          const imageRef = ref(
            storage,
            `gs://genbotnoetic.appspot.com/public/${userId}/${surveyId}`
          ); // Adjust the storage path as needed
          await uploadBytes(imageRef, imageFile);

          // Get the download URL of the uploaded image
          imageUrl = await getDownloadURL(imageRef);
        }

        const genderCalc = [
          {
            "45-49": { male: 0, female: 0 },
            "50-54": { male: 0, female: 0 },
            "55-59": { male: 0, female: 0 },
            "60-64": { male: 0, female: 0 },
            "65-69": { male: 0, female: 0 },
            "70-74": { male: 0, female: 0 },
          },
        ];

        // Create a document with survey metadata
        const surveyData = {
          id: surveyId,
          name: survey.name,
          description: survey.description,
          age: survey.age,
          campaign_type: surveyNameMapping[surveyName],
          language,
          welcomeMsg: survey.welcomeMsg?.trim()?.length
            ? survey.welcomeMsg
            : language === "jap"
              ? welcomeMsgJap
              : welcomeMsgEng,
          created_at: Timestamp.now(),
          image_url: imageUrl, // Save the image URL or null if there's no image
          genderNo: genderCalc,
          questions: {}, screenerQuestions: {}, closingMessages: {},

        };
        // Store the questions as a map and sort by sequence in eng
        if ((language).toLowerCase() === "english") {
          surveyData.questions = DefaultQuestionEng?.[surveyName]
            .slice()
            .sort((a, b) => a.sequence - b.sequence)
            .reduce((questionMap, question) => {
              const questionId = uuidv4();
              questionMap[questionId] = {
                ...question,
                questionId,
              };
              return questionMap;
            }, {});

          surveyData.screenerQuestions = screenerQuestionsEng
            .slice()
            .sort((a, b) => a.sequence - b.sequence)
            .reduce((questionMap, question) => {
              const questionId = uuidv4();
              questionMap[questionId] = {
                ...question,
                questionId,
              };
              return questionMap;
            }, {});

          surveyData.closingMessages = closingmessagesEng
            .sort((a, b) => a.sequence - b.sequence)
        }
        else if ((language).toLowerCase() === "japanese") {
          // Store the questions as a map and sort by sequence in jap
          surveyData.questions = defaultQuestionsJap
            .slice()
            .sort((a, b) => a.sequence - b.sequence)
            .reduce((questionMap, question) => {
              const questionId = uuidv4();
              questionMap[questionId] = {
                ...question,
                questionId,
              };
              return questionMap;
            }, {});

          surveyData.screenerQuestions = screenerQuestionsJap
            .slice()
            .sort((a, b) => a.sequence - b.sequence)
            .reduce((questionMap, question) => {
              const questionId = uuidv4();
              questionMap[questionId] = {
                ...question,
                questionId,
              };
              return questionMap;
            }, {});

          surveyData.closingMessages = closingmessagesJap
            .sort((a, b) => a.sequence - b.sequence)
        }
        const getUser = await getDoc(userRef);

        const spectrumApiPayload = {
          survey_title: survey.name,
          survey_category_code: survey.surveyCategory,
          survey_localization: survey_Localization,
          completes_required: survey.completes_required,
          expected_ir: survey.expected_ir,
          expected_loi: survey.expected_loi,
          offer_price: 1.0,
          live_url: `https://survey.noeticdigital.app/?clientId=${getUser.data()?.uid
            }&surveyId=${surveyId}&survey=${surveyName}&lang=${language}&gender=[%%211%%]&age=[%%212%%])`,
          test_url: `https://staging.survey.noeticdigital.app/?clientId=${getUser.data()?.uid
            }&surveyId=${surveyId}&survey=${surveyName}&lang=${language}&gender=[%%211%%]&age=[%%212%%])`,
          field_time: survey.field_time,
          qualifications: survey.qualifications,
        };

        const { data } = await axios({
          method: "post",
          url: `${process.env.REACT_APP_PS_BASE_URL}/buyers/v2/surveys`,
          data: spectrumApiPayload,
          headers: {
            "access-token": process.env.REACT_APP_PS_ACCESS_TOKEN,
          },
        });
        if (data?.ps_survey_id) {
          await setDoc(surveyRef, {
            ...surveyData,
            ps_survey_id: data?.ps_survey_id,
            completes_required: survey.completes_required,
            no_of_respondents: survey.no_of_respondents,
          });
          surveyDataResponse = {
            ps_id: data?.ps_survey_id,
            survey_id: surveyId,
          };
        }
        await axios({
          method: "post",
          url: `${URL}/create-spreadSheet`,
          params: {
            clientId: getUser.data()?.uid,
            title: surveys[0].name,
            surveyId: surveyId,
            spreadSheetID: getUser?.data()?.spreadSheetID || "",
            surveyName: surveyName,
          },
        })
        return surveyDataResponse
      }
    });

    const createdSurveysPromises = await Promise.all(createSurveyPromises);

    const createdSurveys = createdSurveysPromises.filter(
      (survey) => survey !== null

    );

    const surveyIds = createdSurveys.map((survey) => survey?.survey_id);

    await updateDoc(userRef, {
      surveyIds: arrayUnion(...surveyIds.filter((id) => id !== undefined)),
    });
    return { success: true, surveyDataResponse };
  } catch (error) {
    console.error("Error creating surveys:", error);
    throw error;
  }
};

export const updateSurveyQuestions = async (
  surveyId,
  updatedQuestions,
  updatedScreeningQuestions,
  updatedMessages,
  language,
  surveyName
) => {
  try {
    // Reference to the specific survey document
    const surveyRef = doc(db, "surveys", surveyId);

    // Separate updated questions into screener and regular questions
    const updatedScreenerQuestions = updatedScreeningQuestions.reduce(
      (questionMap, question) => {
        const questionId = uuidv4();
        questionMap[questionId] = {
          ...question,
          questionId,
        };
        return questionMap;
      },
      {}
    );

    const updatedRegularQuestions = updatedQuestions.reduce(
      (questionMap, question) => {
        const questionId = uuidv4();
        questionMap[questionId] = {
          ...question,
          questionId,
        };
        return questionMap;
      },
      {}
    );

    // Update the questions and screeningQuestions fields in the survey document
    await updateDoc(surveyRef, {
      [language]: {
        questions: updatedRegularQuestions,
        screenerQuestions: updatedScreenerQuestions,
        closingMessages: updatedMessages,
      },
    });

    return { success: true };
  } catch (error) {
    throw error;
  }
};

export const updateSurveyDetails = async (
  surveyName,
  surveyId,
  newName,
  newDescription,
  newAge,
  newWelcomeMsg
) => {
  try {
    // Reference to the specific survey document
    const surveyRef = doc(db, "surveys", surveyId);

    // Get the existing survey document
    const surveyDoc = await getDoc(surveyRef);

    if (!surveyDoc.exists()) {
      return { success: false, message: "Survey not found" };
    }

    // Prepare the fields to update
    const fieldsToUpdate = {
      name: newName,
      description: newDescription,
      age: newAge,
      welcomeMsg: newWelcomeMsg,
    };
    // If a new image URL is provided, update it
    // if (newImageUrl) {
    //   fieldsToUpdate.image_url = newImageUrl;
    // }
    // Update the survey document
    await updateDoc(surveyRef, fieldsToUpdate);

    return { success: true };
  } catch (error) {
    console.error("Error updating survey details:", error);
    throw error;
  }
};

export const uploadImage = async (imageFile, surveyId) => {
  try {
    const storageRef = ref(
      storage,
      `gs://genbotnoetic-surveys/${surveyId}.jpg`
    );

    // Adjust the storage path as needed
    const fileName = `${Date.now()}_${imageFile.name}`;

    // Reference to the image file in storage
    const imageFileRef = ref(storageRef, fileName);

    // Read the image file as a base64-encoded string
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const dataURL = event.target.result;
        // Upload the image as a base64-encoded string
        uploadString(imageFileRef, dataURL, "data_url")
          .then(() => {
            // Get the download URL for the uploaded image
            getDownloadURL(imageFileRef).then((imageUrl) => {
              resolve(imageUrl);
            });
          })
          .catch((error) => {
            reject(error);
          });
      };

      reader.readAsDataURL(imageFile);
    });
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const deleteImage = async (surveyId, imageUrl) => {
  try {
    // Construct the reference to the image in storage
    const imageFileRef = ref(storage, imageUrl);

    // Delete the image file from storage
    await deleteObject(imageFileRef);

    // Remove the image_url field from the survey data
    const surveyRef = doc(db, "surveys", surveyId);
    await updateDoc(surveyRef, { image_url: deleteField() });
    return { success: true };
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};

export const defaultQuestions = [
  {
    sequence: 1,
    question: i18n.t("screenerQ1"),
    type: "radio",
    question_type: "quick_replies",
    options: [
      i18n.t("questionere01"),
      i18n.t("questionere02"),
      i18n.t("questionere03"),
      i18n.t("questionere04"),
      i18n.t("questionere05"),
      i18n.t("questionere06"),
    ],
    isScreener: false,
  },
  {
    sequence: 2,
    question: i18n.t("questionereQ2"),
    type: "text",
    isScreener: false,
  },
  {
    sequence: 3,
    question: i18n.t("questionereQ3"),
    type: "radio",
    question_type: "multiselect",
    options: [
      i18n.t("questionereMA01"),
      i18n.t("questionereMA02"),
      i18n.t("questionereMA03"),
      i18n.t("questionereMA04"),
      i18n.t("questionereMA05"),
    ],
    isScreener: false,
  },
  {
    sequence: 4,
    question: i18n.t("questionereQ4"),
    type: "radio",
    question_type: "quick_replies",
    isNested: true,
    nestedQuestion: [
      i18n.t("nestedQuestion1"),
      i18n.t("nestedQuestion2"),
      i18n.t("nestedQuestion3"),
      i18n.t("nestedQuestion4"),
      i18n.t("nestedQuestion5"),
    ],
    options: [
      i18n.t("questionereSA01"),
      i18n.t("questionereSA02"),
      i18n.t("questionereSA03"),
      i18n.t("questionereSA04"),
      i18n.t("questionereSA05"),
    ],
    isScreener: false,
  },
  {
    sequence: 5,
    question: i18n.t("questionereQ5"),
    type: "text",
    isScreener: false,
  },
];
export const DefaultQuestionEng = {
  livingConceptSurveys: [
    {
      sequence: 1,
      question:
        "How interesting do you find {{productName}},  which you have just read?",
      type: "radio",
      question_type: "quick_replies",
      options: [
        "Extremely interesting",
        "Very interesting",
        "Interesting",
        "Not so interesting",
        "Not at all interesting",
        "DK",
      ],
      isScreener: false,
    },
    {
      sequence: 2,
      question: "Why do you think that {{productName}} is {{ans_1}}.",
      type: "text",
      isScreener: false,
    },
    {
      sequence: 3,
      question:
        "If this product becomes available in the future at a fair price you can afford and with adequate support to help you to feel comfortable with using it, how likely would it be for each case? (SA)",
      type: "radio",
      question_type: "quick_replies",
      isNested: true,
      nestedQuestion: [
        "Purchase for yourself",
        "Pruchase for someone else",
        "Ask someone else to buy it for you",
        "Let a professional arrange it for you (reimbursed by insurance)",
        "Let a professional arrange it for you (paid by you)",
      ],
      options: [
        "Definitely I would",
        "Probably I would",
        "May or may not",
        "Probably I would not",
        "Definitely I would not",
      ],
      isScreener: false,
    },
    {
      sequence: 4,
      question:
        "Is there anything else you would like to tell us about your thoughts and feelings about this product. Anything you feel like writing down is fine.",
      type: "text",
      isScreener: false,
    },
    {
      sequence: 5,
      question:
        "Please provide your email address, so we can send your 400 jpy gift card as thanks.",
      type: "text",
      isScreener: false,
    },
  ],
  feedbackSurvey: [
    {
      sequence: 1,
      question: "How satisfied were you with your {{productName}}?",
      type: "radio",
      isScreener: false,
      question_type: "quick_replies",
      options: ["1 ⭐", "2 ⭐⭐", "3 ⭐⭐⭐", "4 ⭐⭐⭐⭐", "5 ⭐⭐⭐⭐⭐"],
    },
    {
      sequence: 2,
      question:
        "How would you rate the [user input (flavour , usability etc.)] of your {{productName}}",
      type: "radio",
      isScreener: false,
      question_type: "quick_replies",
      options: ["1 ⭐", "2 ⭐⭐", "3 ⭐⭐⭐", "4 ⭐⭐⭐⭐", "5 ⭐⭐⭐⭐⭐"],
    },
    {
      sequence: 3,
      question: "How would you rate the value for money of {{productName}} ",
      type: "radio",
      isScreener: false,
      question_type: "quick_replies",
      options: ["1 ⭐", "2 ⭐⭐", "3 ⭐⭐⭐", "4 ⭐⭐⭐⭐", "5 ⭐⭐⭐⭐⭐"],
    },
    {
      sequence: 4,
      question: "Is there any way you'd like to improve the {{productName}}",
      type: "text",
      isScreener: false,
    },
  ],
};

export const defaultQuestionsJap = [
  {
    // questionId: uuidv4(),
    sequence: 1,
    question:
      "これから新しい製品「{{productName}}」についてお聞きします。コンセプトをよくご覧ください。",
    type: "radio",
    question_type: "quick_replies",
    options: [
      "極めて強い興味をもった",
      "強い興味をもった",
      "興味をもった",
      "あまり興味をもてなかった",
      "全く興味をもてなかった",
      "何とも言えない",
    ],
    isScreener: false,
  },
  {
    // questionId: uuidv4(),
    sequence: 2,
    question: "{{ans_1}}」とお答えになった理由をお聞かせください。",
    type: "text",
    isScreener: false,
  },
  // {
  //   // questionId: uuidv4(),
  //   sequence: 3,
  //   question:
  //     "次の選択肢の中から、このような製品アイデアに該当しそうだと思われるものをすべてお答えください。",
  //   type: "radio",
  //   question_type: "multiselect",
  //   options: [
  //     "自分用に購入する",
  //     "家族用に購入する",
  //     "家族に、自分に必要かどうか判断してもらいたい",
  //     "プロの専門家に、自分に必要かどうか判断してもらいたい",
  //     "専門家に鑑定してもらうには",
  //   ],
  //   isScreener: false,
  // },
  {
    // questionId: uuidv4(),
    sequence: 3,
    question:
      "この製品が実際に販売された場合、以下の項目についてどのようにお考えになりますか。※価格がお求めやすく、ユーザーサポートが備わっている状態の製品であるとお考え下さい",
    type: "radio",
    question_type: "quick_replies",
    isNested: true,
    nestedQuestion: [
      "自分用として自分自身が購入する",
      "自分以外の人のために自分自身が購入する",
      "自分用として誰かに購入をお願いする",
      "保険が適用されるなら、医療機関などを通して購入する",
      "保険が適用されなくても、医療機関などを通して購入する",
    ],
    options: [
      "購入する",
      "多分購入する",
      "購入するかどうかわからない",
      "多分購入しない",
      "購入しない",
    ],
    isScreener: false,
  },
  {
    sequence: 4,
    question:
      "このようなアイデアの商品について、何かご意見ご感想がありましたら、どのような小さなことでも結構ですのでぜひともお聞かせください。",
    type: "text",
    isScreener: false,
  },
  {
    sequence: 5,
    question:
      "400円分のamazonコードをお送りしますので、メールアドレスをご記入ください。最後のリンクをクリックすると、コンセプトに関する最後の質問が表示されます。",
    type: "text",
    isScreener: false,
  },
];

export const screenerQuestions = [
  {
    sequence: 1,
    question_type: "text",
    question: i18n.t("screenerQ1"),
    isScreener: true,
  },
  {
    sequence: 2,
    question_type: "quick_replies",
    question: i18n.t("screenerQ2"),
    options: [i18n.t("genderO1"), i18n.t("genderO2")],
    isScreener: true,
  },
  {
    sequence: 3,
    question_type: "quick_replies",
    question: i18n.t("screenerQ3"),
    options: [
      i18n.t("screenerO1"),
      i18n.t("screenerO2"),
      i18n.t("screenerO3"),
      i18n.t("screenerO4"),
      i18n.t("screenerO5"),
    ],
    isScreener: true,
  },
  {
    sequence: 4,
    question_type: "quick_replies",
    question: i18n.t("questionereQ4"),
    options: [
      i18n.t("screenerO1"),
      i18n.t("screenerO2"),
      i18n.t("screenerO3"),
      i18n.t("screenerO4"),
      i18n.t("screenerO5"),
    ],
    isScreener: true,
  },
  {
    sequence: 5,
    question_type: "quick_replies",
    question: i18n.t("questionereQ5"),
    options: [
      i18n.t("screenerO1"),
      i18n.t("screenerO2"),
      i18n.t("screenerO3"),
      i18n.t("screenerO4"),
      i18n.t("screenerO5"),
    ],
    isScreener: true,
  },
  {
    sequence: 6,
    question: i18n.t("questionereQ6"),
    question2: i18n.t("questionereQ6Nested"),
    question_type: "quick_replies",
    options: [i18n.t("yes"), i18n.t("no")],
    isScreener: true,
  },
];

export const screenerQuestionsEng = [
  {
    sequence: 1,
    question: "This survey will help to refine a new product concept",
    question2: "Can you help us?",
    question_type: "quick_replies",
    options: ["Yes", "No"],
    isScreener: true,
    shouldCheckCondition: true,
  },
  {
    sequence: 2,
    question_type: "quick_replies",
    question: "What is your age?",
    options: [
      "Less than 35",
      "35 - 40",
      "41 - 45",
      "46 - 50",
      "51 - 55",
      "56 - 60",
      "61 - 65",
      "66 - 70",
      "71 - 75",
    ],
    isScreener: true,
  },
  {
    sequence: 3,
    question_type: "quick_replies",
    question: "What is your gender?",
    options: ["Male", "Female"],
    isScreener: true,
  },
  {
    sequence: 4,
    question_type: "quick_replies",
    question:
      "I would switch to buying products/ services provided by environmental-friendly companies or brands",
    options: [
      "Strongly Agree",
      "Somewhat agree",
      "Neither can say",
      "Somewhat disagree",
      "Strongly disagree",
    ],
    isDisabled: true,
    isScreener: true,
  },
  // {
  //   sequence: 4,
  //   question_type: "quick_replies",
  //   question:
  //     "I tend to think that society should be more open to Islamic teachings",
  //   options: [
  //     "Strongly Agree",
  //     "Somewhat agree",
  //     "Neither can say",
  //     "Somewhat disagree",
  //     "Strongly disagree",
  //   ],
  //   isScreener: true,
  // },
  {
    sequence: 5,
    question_type: "quick_replies",
    question:
      "I am very open to utilising new technologies such as robotics and enhanced digital services as i age",
    options: [
      "Strongly Agree",
      "Somewhat agree",
      "Neither can say",
      "Somewhat disagree",
      "Strongly disagree",
    ],
    isDisabled: true,
    isScreener: true,
  },
];

export const screenerQuestionsJap = [
  {
    sequence: 1,
    question:
      "お答え頂いた内容は貴重なご意見として、製品改善の参考にさせていただきます",
    question2: "ご協力をお願いできますか？",
    question_type: "quick_replies",
    options: ["はい", "いいえ"],
    isScreener: true,
    shouldCheckCondition: true,
  },
  {
    sequence: 2,
    question_type: "quick_replies",
    question: "あなたの年齢は？",
    options: [
      "45歳未満 ",
      "45 – 49歳",
      "50 - 54歳",
      "55 - 59歳",
      "60 - 64歳",
      "65 - 69歳",
      "70 - 74歳",
      "75歳以上",
    ],
    isScreener: true,
  },
  {
    sequence: 3,
    question_type: "quick_replies",
    question: "あなたの性別は？",
    options: ["男性", "女性"],
    isScreener: true,
  },
  {
    sequence: 4,
    question_type: "quick_replies",
    question: "以下の文章に対してあなたはどの程度そう思いますか。",
    options: [
      "非常にそう思う",
      "そう思う",
      "どちらともいえない",
      "そう思わない",
      "全くそう思わない",
    ],
    isDisabled: true,
    isScreener: true,
  },
  // {
  //   sequence: 4,
  //   question_type: "quick_replies",
  //   question:
  //     "私は、社会は熟練した移民の受け入れにもっと寛容であるべきだと考えている。",
  //   options: [
  //     "非常に同意できる",
  //     "同意できる",
  //     "どちらともいえない",
  //     "同意できない",
  //     "全く同意できない",
  //   ],
  //   isScreener: true,
  // },
  {
    sequence: 5,
    question_type: "quick_replies",
    question:
      "私は歳をとってもロボットや最新式のデジタル機器の利用に特に抵抗を持たないだろう",
    options: [
      "非常にそう思う",
      "そう思う",
      "どちらともいえない",
      "そう思わない",
      "全くそう思わない",
    ],
    isDisabled: true,
    isScreener: true,
  },
];

export const closingmessagesEng = [
  {
    sequence: 1,
    heading: "Goodbye Message",
    value:
      "Thank you for answering these questions. Please read the {{productName}} description again, then follow this URL to answer the final question.",
  },
  {
    sequence: 2,
    heading: "Goodbye URL",
    value: "Add URL if you want to show next questioner",
  },
];

export const closingmessagesJap = [
  {
    sequence: 1,
    heading: "Goodbye Message",
    value:
      "質問に答えてくれてありがとう。{{productName}}の説明をもう一度お読みになり、このURLから最後の質問にお答えください。",
  },
  {
    sequence: 2,
    heading: "Goodbye URL",
    value: "次の質問者を表示したい場合はURLを追加してください",
  },
];

export const welcomeMsgEng =
  "Thank you for joining this survey. It will take around 2 minutes. Please provide your email address at the end, then follow the final link. This is so we can send your 400 yen Amazon gift card code. \n    This survey will help us to improve our new product concept called Camera de la Felicita. Your advice will be carefully considered. \n Your information will be kept completely private. Personal information will only be used for screening purposes to ensure that this survey is relevant to you.";

export const welcomeMsgJap =
  "アンケートにご協力いただきありがとうございます。所要時間は約2分です。最後にメールアドレスをご記入の上、最後のリンクをたどってください。Amazonギフトカード500円分のコードをお送りするためです。 \n この調査は、カメラ デ ラ フェリチータと呼ばれる新しい製品コンセプトを改良するのに役立ちます。皆様からのアドバイスは慎重に検討されます。 \n あなたの情報は完全に非公開にされます。個人情報は、この調査があなたに関連したものであることを確認するためのスクリーニングのためにのみ使用されます。";

function generateUniqueSurveyId() {
  // Generate a unique ID, e.g., using a library like `uuid` or a random string
  return Math.random().toString(36).substring(7);
}
