import { Select } from "@chakra-ui/react";
import React from "react";

const CustomDropdown = (props) => {
  const {
    placeholder,
    name,
    value,
    onChange,
    options = [],
    itemText = "",
  } = props;
  return (
    <Select
      defaultValue={value || ""}
      placeholder={placeholder}
      variant="outline"
      name={name}
      onChange={onChange}
      numeric="numeric"
      style={{ maxHeight: "200px" }} // Apply maxHeight using style
    >
      {options.map((item, ind) => {
        return (
          <option value={item?.value} key={ind}>
            {`${item?.label} ${itemText}`}
          </option>
        );
      })}
    </Select>
  );
};

export default CustomDropdown;
